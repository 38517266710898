<template>
    <section class="">
        <div class="row mx-0 overflow-auto custom-scroll">
            <div class="col">
                <div class="custom-scroll overflow-auto mt-2" style="height:calc(100vh - 160px);">
                    <div class="row mx-0">
                        <div class="col-12 pl-0">
                            <div class="row mx-0 bg-white br-t-8 py-2">
                                <div class="col-auto d-middle">
                                    <div class="bg-general3 text-white br-12 py-1 px-3 d-middle">
                                        Pedidos Fuera de tiempo
                                        <div class="br-20 bg-white d-middle-center mx-2 text-general f-13 f-600 px-1" style="min-width:24px;height:24px;">
                                            {{ pedidos_vencidos.length }}
                                        </div>
                                    </div>
                                    <el-tooltip v-show="cedisNavbar.length == 1 && $can('botones_abastecimiento_entregas_editar_fecha')" placement="bottom" content="Editar Entrega de todos los pedidos fuera de tiempo" effect="light">
                                        <i class="icon-pencil-outline text-general2 hover-icons f-22 mx-2 cr-pointer" @click="editarEntrega(false)" />
                                    </el-tooltip>
                                </div>
                                <div class="col-auto d-middle ml-auto pl-0">
                                    <el-input v-model="buscar" clearable size="small" :placeholder="`Buscar pedido o ${$config.vendedor}`" class="w-100 br-20" />
                                </div>
                            </div>
                        </div>
                        <div class="col-12 pl-0">
                            <el-table
                            :data="pedidos_vencidos.filter(o => !buscar || (o.tendero.toLowerCase().includes(buscar.toLowerCase()) || o.id == buscar) )"
                            class="cr-pointer"
                            header-row-class-name="text-general"
                            stripe
                            style="width:100%"
                            @row-click="seleccionar_pedido"
                            >
                                <el-table-column align="center" min-width="95" label="Pedido">
                                    <template slot-scope="scope">
                                        <div class="row mx-0">
                                            <div v-show="pedido_actual == scope.row.id" class="bg-general br-12" style="width:12px;height:39px;" />
                                            <p class="col cr-pointer d-middle text-general f-600 text-center">
                                                {{ scope.row.id }}
                                            </p>
                                        </div>
                                    </template>
                                </el-table-column>
                                <el-table-column prop="tendero" :label="`${$config.vendedor}`" min-width="180">
                                    <template slot-scope="{row}">
                                        <el-tooltip placement="bottom" effect="light">
                                            <div slot="content">
                                                {{ row.direccion }}<br />{{ row.distancia }} Mts. Aprox.
                                            </div>
                                            <p class="" v-text="row.tendero" />
                                        </el-tooltip>
                                    </template>
                                </el-table-column>
                                <el-table-column
                                prop="cant_productos"
                                label="Productos"
                                align="center"
                                min-width="95"
                                />
                                <el-table-column label="Valor" align="center" min-width="120">
                                    <template slot-scope="{row}">
                                        <p> {{ convertMoney(row.valor_final,row.idm_moneda) }}</p><p />
                                    </template>
                                </el-table-column>
                                <el-table-column label="Estado" align="center" min-width="120">
                                    <template slot-scope="{row}">
                                        <p> {{ fun_estado(row.estado) }}</p><p />
                                    </template>
                                </el-table-column>
                                <el-table-column label="Entrega" align="center" width="100">
                                    <template slot-scope="{row}">
                                        <p> {{ row.entrega_fecha | helper-fecha('DD MMM') }}</p><p />
                                        <p> {{ row.entrega_horario + ':00' }}</p><p />
                                    </template>
                                </el-table-column>
                            </el-table>
                        </div>
                    </div>
                    <div v-for="(data,index) in pedidos_vigentes" :key="index" class="row mx-0 mt-5">
                        <div class="col-12">
                            <div class="row mx-0 bg-white br-t-8 py-3">
                                <div class="col-auto d-middle">
                                    <div class="bg-fondo border text-general br-12 py-1 px-3 d-middle">
                                        Pedidos a tiempo del <span class="text-general f-600 mx-1">{{ data.fecha | helper-fecha('DD MMM YYYY') }} </span>
                                        <div class="rounded-circle bg-general3 text-white d-middle-center mx-2 f-13" style="width:24px;height:24px;">
                                            {{ data.pedidos.length }}
                                        </div>
                                    </div>
                                    <i v-show="id_cedis != 0 && $can('botones_abastecimiento_entregas_editar_fecha')" class="icon-pencil-outline text-gr-general f-22 cr-pointer mx-2 " @click="editarEntrega(true,data.fecha)" />
                                </div>
                            </div>
                        </div>
                        <div class="col">
                            <el-table
                            :data="data.pedidos.filter(o => !buscar || (o.tendero.toLowerCase().includes(buscar.toLowerCase()) || o.id == buscar) )"
                            class="cr-pointer"
                            stripe style="width:100%"
                            @row-click="seleccionar_pedido"
                            >
                                <el-table-column align="center" min-width="95" label="Pedido" class-name="text-general">
                                    <template slot-scope="{row}">
                                        <div class="row mx-0">
                                            <div v-show="pedido_actual == row.id" class="bg-gr-red br-12" style="width:12px;height:39px;" />
                                            <p class="col cr-pointer d-middle text-muted2 f-600 text-center">
                                                {{ row.id }}
                                            </p>
                                        </div>
                                    </template>
                                </el-table-column>
                                <el-table-column prop="tendero" :label="`${$config.vendedor}`" min-width="180">
                                    <template slot-scope="{row}">
                                        <el-tooltip placement="bottom" effect="light">
                                            <div slot="content">
                                                {{ row.direccion }}<br />{{ row.distancia }} Mts. Aprox.
                                            </div>
                                            <p class="" v-text="row.tendero" />
                                        </el-tooltip>
                                    </template>
                                </el-table-column>
                                <el-table-column
                                prop="cant_productos"
                                label="Productos"
                                align="center"
                                min-width="95"
                                />
                                <el-table-column label="Valor" align="center" min-width="120">
                                    <template slot-scope="{row}">
                                        <p> {{ convertMoney(row.valor_final,row.idm_moneda) }}</p><p />
                                    </template>
                                </el-table-column>
                                <el-table-column label="Estado" align="center" min-width="120">
                                    <template slot-scope="{row}">
                                        <p> {{ fun_estado(row.estado) }}</p><p />
                                    </template>
                                </el-table-column>
                                <el-table-column label="Entrega" align="center">
                                    <template slot-scope="{row}">
                                        <p> {{ row.entrega_fecha | helper-fecha('DD MMM') }}</p><p />
                                        <p> {{ row.entrega_horario + ':00 Hrs' }}</p><p />
                                    </template>
                                </el-table-column>
                            </el-table>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col px-0 ml-1 pt-2">
                <div class="row mx-0 bg-white border-bottom br-t-8" style="height:51px;">
                    <template v-if="id_pedido">
                        <div class="text-general px-1 d-middle-center">
                            <div class="btn-action border mx-2 cr-pointer text-general" @click="imprimir">
                                <i class="icon-printer f-20" />
                            </div>
                            <div class="col-auto px-1">
                                <p class="text-general f-18 f-500 lh-18">ID. {{ id_pedido }} </p>
                                <p class="text-general f-12">
                                    <span v-show="pedido.estado === 1">Pedido sin confirmar</span>
                                    <span v-show="pedido.estado === 2"> Pedido Confirmado </span>
                                    <span v-show="pedido.estado === 201">Pedido en alistamiento</span>
                                    <span v-show="pedido.estado === 202"> Pedido en empaque </span>
                                    <span v-show="pedido.estado === 3"> Pedido Enviado</span>
                                    <span v-show="pedido.estado === 4"> Pedido Entregado </span>
                                    <span v-show="[25,26,27].indexOf(pedido.estado) > -1"> Pedido Cancelado </span>
                                </p>
                            </div>
                        </div>
                        <div class="col px-0">
                            <navbar-estados />
                        </div>
                    </template>
                </div>
                <div class="row mx-0 border-bottom bg-white br-t-8" style="height:44px;">
                    <template v-if="id_pedido">
                        <div
                        class="col d-middle-center cr-pointer"
                        :class="tab=='productos'? 'border-b-black text-general':''"
                        @click="tab='productos'"
                        >
                            Productos
                        </div>
                        <div
                        class="col d-middle-center cr-pointer"
                        :class="tab=='detalle'? 'border-b-black text-general':''"
                        @click="tab='detalle'"
                        >
                            Detalle
                        </div>
                    </template>
                </div>
                <div class="mx-0">
                    <productos v-show="tab === 'productos'" @update="listar" />
                    <detalle v-if="tab === 'detalle'" />
                </div>
            </div>
        </div>
        <!-- Partials -->
        <modal-editar-entrega ref="EditarEntrega" :fecha="fecha" :a-tiempo="aTiempo" @update="listar" />
        <modal-imprimir ref="imprimirRecibo" />
    </section>
</template>

<script>
import Entregas from '~/services/entregas/pedidos-admin'
import {mapGetters} from 'vuex'
export default {
    components: {
        modalEditarEntrega: () => import('./partials/modalEditarEntrega'),
        productos: () => import('../components/productos'),
        detalle: () => import('../components/detalle'),
        navbarEstados: () => import('../components/navbarEstados')
    },
    data(){
        return{
            tab:'productos',
            buscar: '',
            pedido_actual: null,
            pedidos_vencidos:[],
            pedidos_vigentes:[],
            fecha:'',
            aTiempo:false
        }
    },
    computed:{
        ...mapGetters({
            cedisNavbar:'cedis/cedisNavbar',
            id_cedis:'cedis/id_cedis',
            id_pedido: 'pedidos/pedidos_admin/id_pedido',
            pedido: 'pedidos/pedidos_admin/pedido',
        })
    },
    watch:{
        id_cedis(val){
            this.listar()
            this.$store.commit('pedidos/pedidos_admin/set_id_pedido', null)
            this.pedido_actual = -1
        }
    },
    mounted(){
        this.listar()
    },
    methods: {
        editarEntrega(aTiempo,fecha=''){
            this.aTiempo = aTiempo
            this.fecha = fecha
            // this.nextick()
            this.$refs.EditarEntrega.toggle();
        },
        async listar(){
            const {data} = await Entregas.listar(this.id_cedis)
            this.pedidos_vencidos = data.pedidos_vencidos
            this.pedidos_vigentes = data.pedidos_vigentes
            // console.log(data);
        },
        seleccionar_pedido(row){
            this.pedido_actual = row.id
            this.$store.commit('pedidos/pedidos_admin/set_id_pedido', row.id)
        },
        fun_estado(state){
            switch (state){
            case 1:
                return 'Sin Confirmar'
            case 2:
                return 'Confirmado'
            case 201:
                return 'Alistado'
            case 202:
                return 'Empacado'
            case 3:
                return 'Enviado'
            default:

            }
        },

        //14 de octubre rediseño abastecimiento
        async imprimir(){
            try {
                if (this.pedido.id_pedido == null){
                    const {data} = await PdfPedidos.generar_pdf_ticket_admin_pedidos({id_pedido: this.id_pedido})
                    const url = window.URL.createObjectURL(new Blob([data], {type:"application/pdf"}));
                    const pdfWindow = window.open(url);
                    pdfWindow.print();
                    return
                }
                this.$refs.imprimirRecibo.toggle(this.id_pedido, this.pedido.id_pedido);

            } catch (e){
                this.error_catch(e)
            }
        },

    }
}
</script>

<style lang="scss" scoped>
.br-12{ border-radius: 12px; }
a{ text-decoration: none;}
.tab-active{ color: #5D5D5D; border-bottom: 2px solid #FF612B !important; }
.tab{color: #5D5D5D; }
</style>
